import { Component, OnInit, ViewChild, Input, ElementRef, Output, EventEmitter } from '@angular/core';
import { Chart } from 'chart.js';
import { ApiService } from '../api.service';
import * as moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import { Key } from 'protractor';

@Component({
  selector: 'app-advanced-result',
  templateUrl: './advanced-result.component.html',
  styleUrls: ['./advanced-result.component.scss']
})
export class AdvancedResultComponent implements OnInit {


  student_data: any = [];
  test_info: any = [];
  result_summary: any = [];
  result: any = {};
  rank: any = {};
  subject_wise_analysis: any = [];
  subjects: any = [];
  question_wise_analysis: any = [];
  subject_wise_comparison: any = [];
  previous_test_comparison: any = [];
  topic_wise_comparison: any = [];
  question_data: any = [];
  topics: any = [];
  tab_result: any = '';
  tab_comparison: any = 'hide';
  load_tab_result: any = true;
  load_tab_comparison: any = false;
  tab_sub_wise_analysis: any = 'active';
  tab_time_mgmnt_analysis_sub_wise: any = '';
  tab_question_wise_analysis: any = '';
  tab_time_mgmnt_n_attempt_analysis_of_tests: any = '';
  tab_subject_wise_analysis_od_tests: any = '';
  tab_section_wise_analysis: any = '';
  tab_previous_and_present_tests: any = '';
  load_tab_sub_wise_analysis: any = true;
  load_tab_time_mgmnt_analysis_sub_wise: any = false;
  load_tab_question_wise_analysis: any = false;
  load_tab_time_mgmnt_n_attempt_analysis_of_tests: any = false;
  load_tab_subject_wise_analysis_od_tests: any = false;
  load_tab_section_wise_analysis: any = false;
  tgClass: any = '';
  checkAns: any = false;
  responseData: any;
  encodedAuth: any;
  resultKey: any;

  constructor(public api: ApiService, public router: Router, public elemRef: ElementRef, private route: ActivatedRoute) {

    this.route.params.subscribe(params => {
      this.resultKey = params['resultKey'];
      console.log(params)
    });
  }

  ngOnInit() {

    //  this.encodedAuth = JSON.parse(sessionStorage.getItem('encoded-auth'));
    this.advance_test_report(this.resultKey);
  }

  advance_test_report(key) {
    this.api.advance_test_report(key).subscribe(resp => {
      console.log(resp);
      this.responseData = resp;
      var data: any = this.responseData.data;
      this.student_data = data.student_data;
      this.test_info = data.test_info;
      this.result_summary = data.result_summary;
      this.result = this.result_summary.obtained_marks;
      this.rank = this.result_summary.rank;
      this.subject_wise_analysis = data.subject_wise_analysis;
      this.subjects = data.subjects;
      this.question_wise_analysis = data.question_wise_analysis;
      this.subject_wise_comparison = data.subject_wise_comparison;
      this.previous_test_comparison = data.previous_test_comparison;
      this.topic_wise_comparison = data.topic_wise_comparison;
      this.question_data = data.question_data;
      this.topics = data.topics;
      MathJax.Hub.Queue(['Typeset', MathJax.Hub]);
      MathJax.Hub.Queue(['Typeset', MathJax.Hub]);
    });

  }

  selectChart(type) {
    if (type == 1) {
      this.tab_sub_wise_analysis = '';
      this.tab_time_mgmnt_analysis_sub_wise = 'active';
      this.tab_question_wise_analysis = '';
      this.tab_time_mgmnt_n_attempt_analysis_of_tests = '';
      this.tab_subject_wise_analysis_od_tests = '';
      this.tab_section_wise_analysis = '';
      this.tab_previous_and_present_tests = '';
      this.load_tab_sub_wise_analysis = false;
      this.load_tab_time_mgmnt_analysis_sub_wise = true;
      this.load_tab_question_wise_analysis = false;
      this.load_tab_time_mgmnt_n_attempt_analysis_of_tests = false;
      this.load_tab_subject_wise_analysis_od_tests = false;
      this.load_tab_section_wise_analysis = false;

      this.timeMgmntPi();
    } else if (type == 2) {
      this.tab_sub_wise_analysis = '';
      this.tab_time_mgmnt_analysis_sub_wise = '';
      this.tab_question_wise_analysis = 'active';
      this.tab_time_mgmnt_n_attempt_analysis_of_tests = '';
      this.tab_subject_wise_analysis_od_tests = '';
      this.tab_section_wise_analysis = '';
      this.tab_previous_and_present_tests = '';
      this.load_tab_sub_wise_analysis = false;
      this.load_tab_time_mgmnt_analysis_sub_wise = false;
      this.load_tab_question_wise_analysis = true;
      this.load_tab_time_mgmnt_n_attempt_analysis_of_tests = false;
      this.load_tab_subject_wise_analysis_od_tests = false;
      this.load_tab_section_wise_analysis = false;
      this.quePi();
    } else if (type == 3) {
      this.tab_sub_wise_analysis = '';
      this.tab_time_mgmnt_analysis_sub_wise = '';
      this.tab_question_wise_analysis = '';
      this.tab_time_mgmnt_n_attempt_analysis_of_tests = 'active';
      this.tab_subject_wise_analysis_od_tests = '';
      this.tab_section_wise_analysis = '';
      this.tab_previous_and_present_tests = '';
      this.load_tab_sub_wise_analysis = false;
      this.load_tab_time_mgmnt_analysis_sub_wise = false;
      this.load_tab_question_wise_analysis = false;
      this.load_tab_time_mgmnt_n_attempt_analysis_of_tests = true;
      this.load_tab_subject_wise_analysis_od_tests = false;
      this.load_tab_section_wise_analysis = false;
      this.timeMgmntLine();
    } else if (type == 4) {
      this.tab_sub_wise_analysis = '';
      this.tab_time_mgmnt_analysis_sub_wise = '';
      this.tab_question_wise_analysis = '';
      this.tab_time_mgmnt_n_attempt_analysis_of_tests = '';
      this.tab_subject_wise_analysis_od_tests = 'active';
      this.tab_section_wise_analysis = '';
      this.tab_previous_and_present_tests = '';
      this.load_tab_sub_wise_analysis = false;
      this.load_tab_time_mgmnt_analysis_sub_wise = false;
      this.load_tab_question_wise_analysis = false;
      this.load_tab_time_mgmnt_n_attempt_analysis_of_tests = false;
      this.load_tab_subject_wise_analysis_od_tests = true;
      this.load_tab_section_wise_analysis = false;
      this.subWiseLine();
    } else if (type == 5) {
      this.tab_sub_wise_analysis = '';
      this.tab_time_mgmnt_analysis_sub_wise = '';
      this.tab_question_wise_analysis = '';
      this.tab_time_mgmnt_n_attempt_analysis_of_tests = '';
      this.tab_subject_wise_analysis_od_tests = '';
      this.tab_section_wise_analysis = 'active';
      this.tab_previous_and_present_tests = '';
      this.load_tab_sub_wise_analysis = false;
      this.load_tab_time_mgmnt_analysis_sub_wise = false;
      this.load_tab_question_wise_analysis = false;
      this.load_tab_time_mgmnt_n_attempt_analysis_of_tests = false;
      this.load_tab_subject_wise_analysis_od_tests = false;
      this.load_tab_section_wise_analysis = true;
      this.secWiseLine();
    }
    else if (type == 6) {
      this.tab_previous_and_present_tests = 'active';
      this.tab_sub_wise_analysis = '';
      this.tab_time_mgmnt_analysis_sub_wise = '';
      this.tab_question_wise_analysis = '';
      this.tab_time_mgmnt_n_attempt_analysis_of_tests = '';
      this.tab_subject_wise_analysis_od_tests = '';
      this.tab_section_wise_analysis = '';
      this.load_tab_sub_wise_analysis = false;
      this.load_tab_time_mgmnt_analysis_sub_wise = false;
      this.load_tab_question_wise_analysis = false;
      this.load_tab_time_mgmnt_n_attempt_analysis_of_tests = false;
      this.load_tab_subject_wise_analysis_od_tests = false;
      this.load_tab_section_wise_analysis = true;
      this.previousAndPresentTestAnalysis();

    }
    else {
      this.tab_sub_wise_analysis = 'active';
      this.tab_time_mgmnt_analysis_sub_wise = '';
      this.tab_question_wise_analysis = '';
      this.tab_time_mgmnt_n_attempt_analysis_of_tests = '';
      this.tab_subject_wise_analysis_od_tests = '';
      this.tab_section_wise_analysis = '';
      this.tab_previous_and_present_tests = '';
      this.load_tab_sub_wise_analysis = true;
      this.load_tab_time_mgmnt_analysis_sub_wise = false;
      this.load_tab_question_wise_analysis = false;
      this.load_tab_time_mgmnt_n_attempt_analysis_of_tests = false;
      this.load_tab_subject_wise_analysis_od_tests = false;
      this.load_tab_section_wise_analysis = false;
    }
  }

  selectTab(type) {
    if (type == 1) {
      /* this.tab_result = '';
      this.tab_comparison = 'active';
      this.load_tab_result = false;
      this.load_tab_comparison = true; */

      this.tab_sub_wise_analysis = '';
      this.tab_time_mgmnt_analysis_sub_wise = '';
      this.tab_question_wise_analysis = '';
      this.tab_time_mgmnt_n_attempt_analysis_of_tests = '';
      this.tab_previous_and_present_tests = 'active'
      this.tab_subject_wise_analysis_od_tests = '';
      this.tab_section_wise_analysis = '';
      this.load_tab_sub_wise_analysis = false;
      this.load_tab_time_mgmnt_analysis_sub_wise = false;
      this.load_tab_question_wise_analysis = false;
      this.load_tab_time_mgmnt_n_attempt_analysis_of_tests = false;
      this.load_tab_subject_wise_analysis_od_tests = false;
      this.load_tab_section_wise_analysis = false;
    } else {
      /* this.tab_result = 'active';
      this.tab_comparison = '';
      this.load_tab_result = true;
      this.load_tab_comparison = false; */

      this.tab_sub_wise_analysis = 'active';
      this.tab_time_mgmnt_analysis_sub_wise = '';
      this.tab_question_wise_analysis = '';
      this.tab_time_mgmnt_n_attempt_analysis_of_tests = '';
      this.tab_subject_wise_analysis_od_tests = '';
      this.tab_section_wise_analysis = '';
      this.tab_previous_and_present_tests = ''
      this.load_tab_sub_wise_analysis = true;
      this.load_tab_time_mgmnt_analysis_sub_wise = false;
      this.load_tab_question_wise_analysis = false;
      this.load_tab_time_mgmnt_n_attempt_analysis_of_tests = false;
      this.load_tab_subject_wise_analysis_od_tests = false;
      this.load_tab_section_wise_analysis = false;
    }
  }

  toggleTab() {
    if (this.tgClass == 'toggle-button-selected') {
      this.tab_result = '';
      this.tab_comparison = 'hide';
      this.load_tab_result = true;
      this.load_tab_comparison = false;
      this.tgClass = '';
      this.selectTab(0);
    } else {
      this.tab_result = 'hide';
      this.tab_comparison = '';
      this.load_tab_result = false;
      this.load_tab_comparison = true;
      this.tgClass = 'toggle-button-selected';
      this.selectTab(1);
      this.previousAndPresentTestAnalysis()
      // this.timeMgmntLine();
    }
  }

  progress(i, j) {
    let value = j;
    if (value > 0) {
      if (value <= 50) {
        document.getElementById('progress-right-' + i).style.transform = 'rotate(' + percentageToDegrees(value) + 'deg)';
      } else {
        document.getElementById('progress-right-' + i).style.transform = 'rotate(180deg)';
        document.getElementById('progress-left-' + i).style.transform = 'rotate(' + percentageToDegrees(j - 50) + 'deg)';
      }
    }

    function percentageToDegrees(percentage) {

      return percentage / 100 * 360

    }
  }

  quePi() {
    var canvas: any = document.getElementById("quePi");
    var ctx = canvas.getContext("2d");
    /* var ctx = document.getElementById('myChart1').getContext('2d'); */
    var myBarChart = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: ['Correct Answers', 'Incorrect Answers', 'Unanswered'],
        datasets: [{
          backgroundColor: ["#2ecc71", "#e74c3c", "#f1c40f"],
          data: [this.result_summary.correct_questions, this.result_summary.incorrect_questions, this.result_summary.unattempted_questions]
        }]
      },

      // Configuration options go here
      options: {
        legend: {
          display: true,
          position: 'right',
        }
      }
    });
  }

  timeMgmntPi() {

    let labels = [];
    let data = [];
    this.subject_wise_analysis.forEach(subject => {
      labels.push(subject.subject_name);
      data.push(parseInt(subject.subject_questions.questions_answered));
    });
    var canvas: any = document.getElementById("timeMgmntPi");
    var ctx = canvas.getContext("2d");
    /* var ctx = document.getElementById('myChart1').getContext('2d'); */
    var myBarChart = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: labels,
        datasets: [{
          backgroundColor: ["#2ecc71", "#e74c3c", "#f1c40f", "#3498db", "#95a5a6", "#9b59b6", "#34495e", "#2ecc71", "#e74c3c", "#f1c40f", "#3498db", "#95a5a6", "#9b59b6", "#34495e"],
          data: data
        }]
      },

      // Configuration options go here
      options: {
        legend: {
          display: true,
          position: 'bottom',
        },
        animation: {
          onComplete: function (animation) {
            var firstSet = animation.chart.config.data.datasets[0].data,
              dataSum = firstSet.reduce((accumulator, currentValue) => accumulator + currentValue);

            if (typeof firstSet !== "object" || dataSum === 0) {
              document.getElementById('no-timeMgmntPi-data').style.display = 'flex';
              document.getElementById('timeMgmntPi').style.display = 'none'
            } else {
              document.getElementById('no-timeMgmntPi-data').style.display = 'none';
            }
          }
        }
      }
    });

    let blabels = [];
    let bdata = [];
    this.subject_wise_analysis.forEach(subject => {
      blabels.push(subject.subject_name);
      bdata.push(subject.subject_questions.time_taken / 60000);
    });
    var canvas: any = document.getElementById("timeMgmntBar");
    var ctx = canvas.getContext("2d");
    /* var ctx = document.getElementById('myChart').getContext('2d'); */
    var myBarChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: blabels,
        datasets: [{
          label: '',
          backgroundColor: ["#2ecc71", "#e74c3c", "#f1c40f", "#3498db", "#95a5a6", "#9b59b6", "#34495e", "#2ecc71", "#e74c3c", "#f1c40f", "#3498db", "#95a5a6", "#9b59b6", "#34495e"],
          data: bdata
        }]
      },

      // Configuration options go here
      options: {
        legend: {
          display: false,
          position: 'bottom',
        },
        animation: {
          onComplete: function (animation) {
            var firstSet = animation.chart.config.data.datasets[0].data,
              dataSum = firstSet.reduce((accumulator, currentValue) => accumulator + currentValue);

            if (typeof firstSet !== "object" || dataSum === 0) {
              document.getElementById('no-timeMgmntBar-data').style.display = 'block';
              document.getElementById('timeMgmntBar').style.display = 'none'
            } else {
              document.getElementById('no-timeMgmntBar-data').style.display = 'none';
              document.getElementById('timeMgmntBar').style.marginTop = '1.5em'
            }
          }
        },
        scales: {
          xAxes: [{
            barPercentage: 0.4
          }]
        }
      }
    });
  }

  calcTimeTaken(time){
    return Math.floor(time/60) +' m '+ time % 60 +' s';
  }

  previousAndPresentTestAnalysis() {
    let yLabels = [];
    let xLabels = [];
    let marksObtained = [];
    this.calculateExtraTimeTaken(this.previous_test_comparison.tests);

    console.log('after change:', this.previous_test_comparison);
      this.previous_test_comparison.tests.forEach((key, value) => {
        var test_name = '';
        if(key.test_name.length > 10)
        {
          test_name = key.test_name.substring(0, 10) + '...';
        } else {
          test_name = key.test_name;
        }
        
        yLabels.push(key.percentage);
        xLabels.push(test_name);
        marksObtained.push(key.marks_obtained + ' / ' + parseInt(key.maximum_marks));
      });
      
      console.log('ques attemp data:', this.previous_test_comparison.tests);
      /* console.log('y labels are:',yLabels);
      console.log('x labels are:',xLabels); */
      var id = 'previousPresentTestAnalysis';
      var canvas: any = document.getElementById(id);
      var ctx = canvas.getContext("2d");
      var myLine = new Chart(ctx, {
        type: 'line',
        data: {
          labels: xLabels,
          datasets: [{
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgb(255, 99, 132)',
            data: yLabels,
            fill: false,
          }]
        },
        options: {
          title: {
            display: true,
            text: 'Analysis of Previous and Present tests'
          }, 
          responsive: true,
          legend: {
            display: false,
          },
          tooltips: {
            mode: 'label',
            callbacks: {
              /* title: function(t,d){
                return d.labels[t[0].label] ;
              }, */
              beforeFooter: function (tooltipItems, value) {
                for (var i = 0; i < marksObtained.length; i++) {
                  return marksObtained[tooltipItems[0].index];
                }
              },
              footer: function (tooltipItems, data) {
                return 'Marks Obtained';
              }

            }
          },
          scales: {
            xAxes: [{
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Tests'
              }
            }],
            yAxes: [{
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Percentage'
              },
              ticks: {
                beginAtZero: true,
              }
            }]
          },
        }
      })
    //})
  }

  calculateExtraTimeTaken(tests) {
    tests.forEach((key, val) => {
      key.diff = key.total_alloted_time - key.total_time_taken;
      key.absDiff = Math.abs(key.diff);
      if (key.absDiff > 60) {
        key.formattedTimeTaken = Math.floor(key.absDiff / 3600) + 'h ' + Math.floor(key.absDiff % 3600 / 60) + 'm ' + Math.floor(key.absDiff % 3600 % 60) + 's ';
      }
      else {
        key.formattedTimeTaken = key.absDiff + ' s';
      }
      if (key.diff >= 0) {
        key.timeSaved = true;
        key.extraTimeTaken = false;
      }
      else {
        key.timeSaved = false;
        key.extraTimeTaken = true;
      }
    })

  }

  timeMgmntLine() {

    this.subject_wise_comparison.forEach((sub, index) => {
      let linelabels = [];
      let linedata = [];
      var str = [];
      sub.tests_data.forEach((test, index) => {
        linelabels.push(test.test_name);
        linedata.push(test.questions_answered / test.total_questions * 100);
        str.push(test.questions_answered + ' / ' + test.total_questions);
      });
      var id = "timeMgmntLine-" + index;
      var nid = "no-timeMgmntLine-data-" + index;
      var canvas: any = document.getElementById(id);
      var ctx = canvas.getContext("2d");
      /* var ctx = document.getElementById("canvas-"+index).getContext("2d"); */
      var myLine = new Chart(ctx, {
        type: 'line',
        data: {
          labels: linelabels,
          datasets: [{
            label: sub.subject_name,
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgb(255, 99, 132)',
            data: linedata,
            fill: false,
          }]
        },
        options: {
          legend: {
            display: false,
          },
          responsive: true,
          tooltips: {
            mode: 'label',
            callbacks: {
              title: function (t, d) {
                return d.labels[t[0].index];
              },
              beforeFooter: function (tooltipItems, data) {
                return str[tooltipItems[0].index];
              },
              footer: function (tooltipItems, data) {
                return 'Questions Attempted';
              }
            }
          },
          scales: {
            xAxes: [{
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Tests'
              },
              ticks: {
                callback: function (value) {
                  var str = value.substr(0, 10);
                  return str + '...';//truncate
                },
              }
            }],
            yAxes: [{
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Questions Attempted (in %)'
              },
              ticks: {
                beginAtZero: true,
              }
            }]
          },
          animation: {
            onComplete: function (animation) {
              var firstSet = animation.chart.config.data.datasets[0].data.length

              if (firstSet < 2) {
                document.getElementById(nid).style.display = 'flex';
                document.getElementById(id).style.display = 'none'
              } else {
                document.getElementById(nid).style.display = 'none';
              }
            }
          }
        }
      });

    });
  }

  subWiseLine() {

    this.subject_wise_comparison.forEach((sub, index) => {
      let linelabels = [];
      let linedata = [];
      var str = [];
      sub.tests_data.forEach((test, index) => {
        linelabels.push(test.test_name);
        linedata.push(test.marks_obtained / test.maximum_marks * 100);
        str.push(test.marks_obtained + ' / ' + test.maximum_marks);
      });
      var id = "subWiseLine-" + index;
      var nid = "no-subWiseLine-data-" + index;
      var canvas: any = document.getElementById(id);
      var ctx = canvas.getContext("2d");
      /* var ctx = document.getElementById("canvas-"+index).getContext("2d"); */
      var myLine = new Chart(ctx, {
        type: 'line',
        data: {
          labels: linelabels,
          datasets: [{
            label: sub.subject_name,
            backgroundColor: '#2ecc71',
            borderColor: '#2ecc71',
            data: linedata,
            fill: false,
          }]
        },
        options: {
          legend: {
            display: false,
          },
          responsive: true,
          tooltips: {
            mode: 'label',
            callbacks: {
              title: function (t, d) {
                return d.labels[t[0].index];
              },
              beforeFooter: function (tooltipItems, data) {
                return str[tooltipItems[0].index];
              },
              footer: function (tooltipItems, data) {
                return 'Marks Obtained';
              }
            }
          },
          hover: {
            mode: 'nearest',
            intersect: true
          },
          scales: {
            xAxes: [{
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Tests'
              },
              ticks: {
                callback: function (value) {
                  var str = value.substr(0, 10);
                  return str + '...';//truncate
                },
              }
            }],
            yAxes: [{
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Marks Obtained in (%)'
              },
              ticks: {
                beginAtZero: true,
              }
            }]
          },
          animation: {
            onComplete: function (animation) {
              var firstSet = animation.chart.config.data.datasets[0].data.length

              if (firstSet < 2) {
                document.getElementById(nid).style.display = 'flex';
                document.getElementById(id).style.display = 'none'
              } else {
                document.getElementById(nid).style.display = 'none';
              }
            }
          }
        }
      });

    });
  }

  secWiseLine() {

    this.topic_wise_comparison.forEach((topic, index) => {
      let linelabels = [];
      let linedata = [];
      var str = [];
      topic.tests_data.forEach((test, index) => {
        linelabels.push(test.test_name);
        linedata.push(test.marks_obtained / test.maximum_marks * 100);
        str.push(test.marks_obtained + ' / ' + test.maximum_marks);
      });
      var id = "secWiseLine-" + index;
      var nid = "no-secWiseLine-data-" + index;
      var canvas: any = document.getElementById(id);
      var ctx = canvas.getContext("2d");
      /* var ctx = document.getElementById("canvas-"+index).getContext("2d"); */
      var myLine = new Chart(ctx, {
        type: 'line',
        data: {
          labels: linelabels,
          datasets: [{
            label: topic.subject_name,
            backgroundColor: '#3498db',
            borderColor: '#3498db',
            data: linedata,
            fill: false,
          }]
        },
        options: {
          legend: {
            display: false,
          },
          responsive: true,
          tooltips: {
            mode: 'label',
            callbacks: {
              title: function (t, d) {
                return d.labels[t[0].index];
              },
              beforeFooter: function (tooltipItems, data) {
                return str[tooltipItems[0].index];
              },
              footer: function (tooltipItems, data) {
                return 'Marks Obtained';
              }
            }
          },
          hover: {
            mode: 'nearest',
            intersect: true
          },
          scales: {
            xAxes: [{
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Tests'
              },
              ticks: {
                callback: function (value) {
                  var str = value.substr(0, 10);
                  return str + '...';//truncate
                },
              }
            }],
            yAxes: [{
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Marks Obtained (in %)'
              },
              ticks: {
                beginAtZero: true,
              }
            }]
          },
          animation: {
            onComplete: function (animation) {
              var firstSet = animation.chart.config.data.datasets[0].data.length

              if (firstSet < 2) {
                document.getElementById(nid).style.display = 'flex';
                document.getElementById(id).style.display = 'none'
              } else {
                document.getElementById(nid).style.display = 'none';
              }
            }
          }
        }
      });

    });
  }

  getOption(option_id) {
    option_id = parseInt(option_id);
    switch (option_id) {
      case 1: return 'A';
        break;
      case 2: return 'B';
        break;
      case 3: return 'C';
        break;
      case 4: return 'D';
        break;
      case 5: return 'E';
        break;
      case 6: return 'F';
        break;
      case 7: return 'G';
        break;
      case 8: return 'H';
        break;
      case 9: return 'I';
        break;
      case 10: return 'J';
        break;
      default: return '';
        break;
    }
    return String.fromCharCode(option_id);
  }

  // for multiple choice ques

  getCorrectAnswerOptions(choices) {
    let check_correct_ans: any = [];
    choices.forEach((key, index) => {
      if (key.is_correct == 1) {
        check_correct_ans.push(this.getCharacter(index + 65));
      }
    });
    return check_correct_ans.toString();
  }

  getCharacter(index) {
    return String.fromCharCode(index);
  }

  convertMinutesToHours(time){
    var time = time.toFixed(2);
    if(time <= 60){
      return time + ' mins';
    }
    else{
      return (Math.floor(time/60) + ' hours ' + time % 60 + ' mins');
    }
  }

  is_correct(question) {
    let std_ans = question.student_answer.attempt_answer;
    let ans = std_ans.split(",");
    let check_arr = [];
    var i = 0;
    question.options.forEach((option, index) => {
      var correct_ans = option.correct_ans.split("|");
      correct_ans = correct_ans.reverse();
      check_arr[index] = option.option_id + '=' + correct_ans[0];
      if (check_arr[index] === ans[index]) {
        this.checkAns = true;
      } else {
        i++;
        this.checkAns = false;
      }
    });
    if (i == 0) {
      this.checkAns = true;
    } else {
      this.checkAns = false;
    }
    return this.checkAns;
  }

  getAnswer(question) {
    if (question.ques_type != 6 && question.ques_type != 7) {
      if (question.student_answer.attempt_answer == '-1') {
        return 'NA';
      } else {
        if (question.ques_type == 2) {
          return question.student_answer.attempt_answer;
        } else if (question.ques_type == 3) {
          let std_ans = question.student_answer.attempt_answer;
          let ans = std_ans.split(",");
          let check_arr = [];
          ans.forEach((val, index) => {
            if (val == '-1') {
              check_arr[index] = 'NA';
            } else {
              check_arr[index] = this.getOption(val);
            }
          });
          return check_arr.toString();
        } else {
          return this.getOption(question.student_answer.attempt_answer);
        }

      }
    } else {
      let std_ans = question.student_answer.attempt_answer;
      let ans = std_ans.split(",");
      let check_arr = [];
      ans.forEach((val, index) => {
        let answer = val.split("=");
        if (answer[1] == '-1') {
          check_arr[index] = (index + 1) + ' = NA';
        } else {
          check_arr[index] = (index + 1) + ' = ' + this.getOption(answer[1]);
        }
      });
      return check_arr.toString();
    }
  }
}

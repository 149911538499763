import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({name: 'safeHtml'})
export class safeHtmlPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {
    }
  
    transform(content: string): SafeHtml {
      return this.sanitizer.bypassSecurityTrustHtml(content);
    }
}
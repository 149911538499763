import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-english',
  templateUrl: './english.component.html',
  styleUrls: ['./english.component.scss']
})
export class EnglishComponent implements OnInit {

  @Input()
  test_instructions: any;
  
  constructor() { }

  ngOnInit() {
  }

}

import { Injectable } from '@angular/core';
import { CanActivate, Router }    from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import * as cons from './config/constants';
import * as globals from './config/globals';

@Injectable()
export class ApiService {

  //Constant URLs
  API_URL: string = cons.constants[globals.globals.env].apiUrl;
  PROCTUR_URL: string = cons.constants[globals.globals.env].procturUrl;

  deviceId: any;
  authId: any;
  sessionKey: any;

  deviceHeaders: any = [];
  sessionHeaders: any = [];

  institute_id: any;
  account_id: any;
  student_data: any = [];
  test_data: any = [];
  attempt_data: any = [];
  encodedAuth: any;

  
  

  constructor(private http: HttpClient, private router: Router, private cookieService: CookieService) {
    this.deviceId = localStorage.getItem('identityKey');
    
    this.deviceHeaders = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'X-Device-Key': this.deviceId
      })
    };
  }

  setSessionHeaders()
  {
    let account_data = JSON.parse(sessionStorage.getItem('institute_data'));
    let test_data = JSON.parse(sessionStorage.getItem('test_data'));
    let student_data = JSON.parse(sessionStorage.getItem('student_data'));
    let attempt_data = JSON.parse(sessionStorage.getItem('attempt_data'));
    this.encodedAuth = JSON.parse(sessionStorage.getItem('encoded-auth'));

   // console.log('Attempt data is',attempt_data);

    if(student_data !=undefined && test_data != undefined && account_data != undefined)
    {
      this.institute_id = account_data.institute_id;
      this.account_id = account_data.account_id;
      this.student_data = student_data;
      this.test_data = test_data;
      this.attempt_data = attempt_data;

      this.sessionKey = student_data.userid+':'
                        +test_data.test_id+':'
                        +account_data.account_id+':'
                        +sessionStorage.getItem('sessionKey');
      this.sessionKey = btoa(this.sessionKey);

      this.sessionHeaders = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'X-Device-Key': this.deviceId,
          'X-Session-Key': this.sessionKey
        })
      };
    }
  }

//Common Methods
  api_get(url){
      let deviceInfo = JSON.parse(localStorage.getItem('deviceInfo'));
      let device = {
        'device' : deviceInfo.device,
        'browser' : deviceInfo.browser+' '+deviceInfo.browser_version,
        'os' : deviceInfo.os+' '+deviceInfo.os_version
      };

      let options = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'X-Device-Key': this.deviceId,
          'X-Device-Info': btoa(JSON.stringify(device))
        })
      };
      return this.http.get(this.API_URL+url, options);
  }


  SubmitTestExc(submit , dataa ){
    if(this.institute_id == undefined)
    {
      this.setSessionHeaders();
    }
    if(submit == 1)
    {
      let datanew = JSON.parse(dataa)
      datanew.isComplete=  1
      let data = { 'data' : datanew, 'institute_id' : this.institute_id };
      return this.http.post(this.API_URL+'/submitTestnew', data, this.sessionHeaders);
    }
  }

  saveTestStatus(submit = 0){
    if(this.institute_id == undefined)
    {
      this.setSessionHeaders();
    }
    
    //let data = JSON.parse(sessionStorage.getItem('test_status'));
    if(submit == 1)
    {
      let data = { 'data' : JSON.parse(sessionStorage.getItem('test_status')), 'institute_id' : this.institute_id };
      return this.http.post(this.API_URL+'/submitTest', data, this.sessionHeaders);
    }
    else{
      let data = { 'data' : JSON.parse(sessionStorage.getItem('test_status')) };
      return this.http.post(this.API_URL+'/saveTestState', data, this.sessionHeaders);
    }
    
  }

  submitTest(){
    if(this.institute_id == undefined)
    {
      this.setSessionHeaders();
    }
    let data = JSON.parse(sessionStorage.getItem('test_status'));
    return this.http.post(this.API_URL+'/submitTest', data, this.sessionHeaders);
  }

  fetch_result(){
    if(this.institute_id == undefined)
    {
      this.setSessionHeaders();
    }
    return this.http.get(this.API_URL+'/result/'+this.institute_id, this.sessionHeaders);
  }

  auth_request(auth_token)
  {
    if(sessionStorage.getItem("sso_login_details") === null){
      return this.api_get('/auth/'+auth_token);
    } else {
      let url = '/auth/'+auth_token;
      let deviceInfo = JSON.parse(localStorage.getItem('deviceInfo'));
      let device = {
        'device' : deviceInfo.device,
        'browser' : deviceInfo.browser+' '+deviceInfo.browser_version,
        'os' : deviceInfo.os+' '+deviceInfo.os_version
      };

      let options = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'X-Device-Key': this.deviceId,
          'X-Device-Info': btoa(JSON.stringify(device))
        })
      };

      let data = { 'data' : JSON.parse(sessionStorage.getItem('sso_login_details')), 'token' : JSON.parse(sessionStorage.getItem('login_token')) };
      return this.http.post(this.API_URL+url, data, options);
    }
    
  }

  changeSession(user_id ) {
    /* return this.api_get('/changeSession/'+user_id); */
    let account_data = JSON.parse(sessionStorage.getItem('institute_data'));
      var insta_id=0;
      if (account_data != undefined) {
        insta_id = account_data.institute_id;
      }
    if(sessionStorage.getItem("sso_login_details") === null){
      return this.api_get('/changeSession/'+user_id +"/" + insta_id);
    } else {
      let url = '/changeSession/'+user_id +"/" + insta_id;
      let deviceInfo = JSON.parse(localStorage.getItem('deviceInfo'));
      let device = {
        'device' : deviceInfo.device,
        'browser' : deviceInfo.browser+' '+deviceInfo.browser_version,
        'os' : deviceInfo.os+' '+deviceInfo.os_version
      };

      let options = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'X-Device-Key': this.deviceId,
          'X-Device-Info': btoa(JSON.stringify(device))
        })
      };

      let data = { 'data' : JSON.parse(sessionStorage.getItem('sso_login_details')), 'token' : JSON.parse(sessionStorage.getItem('login_token')) };
      return this.http.post(this.API_URL+url, data, options);
    }
  }

  server_time()
  {
    return this.api_get('/time');
  }

  begin_test()
  {
    this.setSessionHeaders();
    if(sessionStorage.getItem("component_details") === null){
      return this.http.get(this.API_URL+'/beginTest', this.sessionHeaders);
    } else {
      let data = sessionStorage.getItem('component_details');
      var temp = {
        'component_details': data
      };
      return this.http.post(this.API_URL+'/beginTest', temp, this.sessionHeaders);
    }
  }

  advance_test_report(auth)
  {
    if(this.institute_id == undefined)
    {
      this.setSessionHeaders();
    }
   // console.log('encoded data',this.encodedAuth)
    /* console.log(this.account_id);
    console.log(this.student_data.userid);
    console.log(this.test_data.test_id); */
    /* return this.http.get(this.API_URL+'/advanced-test-report/' + this.account_id + '/' + this.student_data.userid + '/' + this.test_data.test_id + '/' + this.attempt_data.test_attempt_id); */
    /* return this.http.get(this.API_URL+'/advanced-test-report/52/107576/19/151'); */
    return this.http.get(this.API_URL+'/advanced-test-report/'+auth);
   // return this.http.get(this.API_URL+'/advanced-test-report/NTI6MTUxODQ2OjE2OjE5NTkw');
    
    
  }
 //login
 alternateLoginCall(tokenPayload){
   this.setSessionHeaders();
   return this.http.post(this.PROCTUR_URL +'/alternateLogin/sso', tokenPayload)
 }
  //api call file upload to AWS in case of subjective questions
  uploadFileApi(payload){
    var headers ={
      'X-Session-Key': this.sessionKey,
      'X-Device-Key': this.deviceId,
/*       'Access-Control-Allow-Origin': "same-origin"
 */    }
    return this.http.post(this.API_URL +'/getImageUploadUrl', payload, {
      headers: headers
    });
  }
  uploadFileInAWS(fileObject, data){
    return this.http.put(data.s3_url, fileObject,{
      headers:{
      'Content-Type': data.type,
      }
    });
  }
  getCurrentTimestamp(){
    return this.http.get('https://api.examdesk.co/v1/examPanel/time');
  }

}
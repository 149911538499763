
export const constants: any = {

  local : {
    apiUrl          : "https://localhost:9001/v1/examPanel",
    examdeskApiUrl  : "http://localhost:9000",
    webUrl          : "https://localhost:4200"
  },

  testbed : {
    apiUrl          : "https://test999.examdesk.co/api/public/v1/examPanel",
    examdeskApiUrl  : "http://test999.examdesk.co",
    webUrl          : "http://testpanel999.examdesk.co",
    procturUrl      : "https://test999.proctur.com/StdMgmtWebAPI/api/v1",
  },
  uat : {
    apiUrl          : "https://onlinetestapi.proctur.com/v1/examPanel",
    examdeskApiUrl  : "https://onlinetestuat.proctur.com",
    webUrl          : "https://starttest.proctur.com",
    procturUrl      : "https://uat.proctur.com/StdMgmtWebAPI/api/v1"
  },

  prod : {
    apiUrl          : "https://loadapi.examdesk.co/v1/examPanel",
    examdeskApiUrl  : "https://examdesk.co",
    webUrl          : "http://onlinetest.eduims.com",
    procturUrl      : "https://api.proctur.com/StdMgmtWebAPI/api/v1"
  },
  sit : {
    apiUrl          : "https://onlinetestsitapi.proctur.com/v1/examPanel",
    examdeskApiUrl  : "https://onlinetestsit.proctur.com",
    webUrl          : "https://testpanelsit.proctur.com",
    procturUrl      : "https://sitv2.proctur.com/StdMgmtWebAPI/api/v1"
  }

};	
import { Component, OnInit, EventEmitter, Output, ViewChild, Input, HostListener } from '@angular/core';
import * as globals from './../config/globals';
import { GuestQuestionWiseReportComponent } from './guestQuestionReport/guest-question-wise-report.component';
import { Chart } from 'chart.js';
import { GuestSubjectWiseReportComponent } from './guestSubjectReport/guest-subject-report.component';
import { constants } from 'os';
import LZString from '../lz-string.js';



@Component({
  selector: 'app-guest-score',
  templateUrl: './guest-score.component.html',
  styleUrls: ['./guest-score.component.scss']
})
export class GuestScoreComponent implements OnInit {
  questions: any;
  test: any;
  user_answers: any;
  exam_date: any;
  exam_start_time: any;
  exam_end_time: any;
  student_data: any;
  time_taken: any;
  globals: any = globals.globals;
  loadSubjectWiseComponent = false;
  loadQuestionWiseComponent = false;
  loadTestSummary = false;
  tab_subject_wise_analysis = '';
  tab_question_wise_analysis = '';
  tab_summary = 'active';
  chartType: any = 0;
  answerReport: any = [];
  marksReport: any = [];
  colorArray: any = [];
  subjects: any;
  student_name: any;
  loading: boolean = true;
  studentReport: any = [];
  percentage: any;
  @ViewChild('chartCanvas') canvas: any;
  @ViewChild('marksChart') marks: any;

  @Input() data: any;

  @ViewChild(GuestQuestionWiseReportComponent) guestQuesObj: GuestQuestionWiseReportComponent;
  @ViewChild(GuestSubjectWiseReportComponent) guestSujectObj: GuestSubjectWiseReportComponent;


  ans_pool: any = [];
  ans_stats: any = {
    correct_ans: 0,
    incorrect_ans: 0,
    not_attempted_ans: 0,
    marks_obtained: 0,
    correct_marks: 0,
    incorrect_ans_marks: 0,
    not_attempted_marks: 0,
    total_time_taken: 0,
    string_time_taken: ''
  }

  constructor() {

  }

  ngOnInit() {
    let saved = JSON.parse(sessionStorage.getItem('is_data_compress'));
    if(saved == 1) {
      this.questions = JSON.parse(LZString.decompress(sessionStorage.getItem('ques_data')));
    } else {
      this.questions = JSON.parse(sessionStorage.getItem('ques_data'));
    }
    /* this.questions = JSON.parse(sessionStorage.getItem('ques_data')); */
    this.test = JSON.parse(sessionStorage.getItem('test_data'));
    this.user_answers = JSON.parse(sessionStorage.getItem('test_status'));
    this.student_data = JSON.parse(sessionStorage.getItem('student_data'));
    this.student_name = this.student_data.name.split(' ')[0];
    console.log('Student name is:', this.student_name);
    var temp = this.user_answers.attempt_time.split('#');
    console.log(temp);
    var sum = 0;
    for (var i = 0; i < temp.length; i++) {
      sum = sum + parseInt(temp[i]);
    }
    this.time_taken = ("0" + (Math.floor((sum / (1000 * 60 * 60)) % 24))).slice(-2) + ' : ' + ("0" + Math.floor((sum / (1000 * 60)) % 60)).slice(-2) + ' : ' + ("0" + (Math.floor((sum / 1000) % 60))).slice(-2);


    console.log(this.test);
    console.log(this.user_answers);
    console.log(this.questions);


    var obj: any = sessionStorage.getItem('exam-date');
    var date = new Date(obj * 1000);
    this.exam_date = this.pad(date.getDate()) + '-' + date.toLocaleString('default', { month: 'short' }) + '-' + date.getFullYear();
    this.exam_start_time = this.pad(date.getHours()) + ' : ' + this.pad(date.getMinutes()) + ' : ' + this.pad(date.getSeconds());
    var end_time: any = sessionStorage.getItem('test_end_time');
    var end_dt = new Date(end_time * 1000);
    this.exam_end_time = this.pad(end_dt.getHours()) + ' : ' + this.pad(end_dt.getMinutes()) + ' : ' + this.pad(end_dt.getSeconds());
  
    this.format_user_ans();
    this.evaluate();
    this.selectChart(2);
    this.loading = false;
    this.calcPercent();
    this.guestQuesObj.ngOnInit();
  }
  @HostListener('document:contextmenu', ['$event'])
  onDocumentRightClick(event) {
    event.preventDefault();
  }

  @HostListener('document:backbutton', ['$event'])
  onPopState(event) {
    event.preventDefault();
    console.log(event);
  }
  event(ev) {
    this.subjects = ev;
    console.log('new subjs are:', this.subjects);
    if (this.chartType == 2) {
      this.renderMarksReportChart()
    }
  }
 
 /*  getStudentData(ev) {
    this.studentReport = ev;
    console.log('data from ques comp:', this.studentReport);
    sessionStorage.setItem('student-answer-report', JSON.stringify(this.studentReport));
  }  */
  renderAnswerReportChart() {
    this.answerReport = [];
    console.log(this.ans_stats);

    this.answerReport.push(this.ans_stats['correct_ans']);
    this.answerReport.push(this.ans_stats['incorrect_ans']);
    this.answerReport.push(this.ans_stats['not_attempted_ans']);

    let ctx = this.canvas.nativeElement.getContext('2d');
    let chart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: ['Correct Answer', 'Incorrect Answer', 'Unattempted Answer'],
        datasets: [{
          label: '# of Votes',
          data: this.answerReport,
          backgroundColor: [

            '#00FF00',
            '#FF0000',
            '#FFFF00',

          ],
          borderWidth: 0
        }]
      },
      options: {
        responsive: true,
        title: {
          display: true,
          text: 'Answer Based Analysis'
        },
        legend: {
          display: false,
          position: 'bottom',

        }
      }
    });
  }

  renderMarksReportChart() {
    this.marksReport = [];
    console.log(this.ans_pool);
    /* this.marksReport.push(this.ans_stats['correct_marks']);
    this.marksReport.push(this.ans_stats['incorrect_ans_marks']);
    this.marksReport.push(this.ans_stats['not_attempted_marks']); */
    //  var subjects = JSON.parse(sessionStorage.getItem('subject-array'));
    var subjectNames = this.subjects.map(obj => {
      return obj.subject_name;
    });
    this.subjects.forEach(obj => {
      var sum = 0;
      obj.questionArray.forEach(ob => {
        if (ob.isCorrect == 1) {
          sum = sum + parseInt(ob.positiveMarks);
        }
        else if (ob.isCorrect == 0 && ob.studentAnswer !== '-1') {
          sum = sum + parseInt(ob.negativeMarks);
        }
        else if (ob.studentAnswer == '-1') {
          sum = sum + parseInt(ob.notAttemptedMarks);
        }

      });
      this.marksReport.push(sum);
    });
    var removeZeroes = this.marksReport.filter(obj => obj !== 0);


    var letters = '0123456789ABCDEF';
    for (var i = 0; i < subjectNames.length; i++) {
      var color = '#';
      for (var j = 0; j < 6; j++) {
        color = color + letters[Math.floor(Math.random() * 16)];

      }
      this.colorArray.push(color);
    }
    if (removeZeroes.length) {
      let ct = this.marks.nativeElement.getContext('2d');
      let chart = new Chart(ct, {
        type: 'doughnut',
        data: {
          labels: subjectNames,
          datasets: [
            {
              label: "Marks",
              backgroundColor: this.colorArray,
              borderWidth: 0,
              data: this.marksReport
            }
          ]
        },
        options: {
          responsive: true,
          legend: {
            display: false,
            position: 'bottom'
          },
          title: {
            display: true,
            text: 'Subject Based Analysis'
          },
        }
      });
    }

  }

  format_user_ans() {
    let questions = this.user_answers.questions.split('#');
    let answers = this.user_answers.answers.split('#');
    let attempt_time = this.user_answers.attempt_time.split('#');
    let question_state = this.user_answers.question_state.split('#');
    let subjective_answer = this.user_answers.subjective_answer;

    sessionStorage.setItem('user-answers', JSON.stringify(answers));
    console.log('answers array:', answers);
    questions.map((que_id, key) => {
      let ans = -1;
      if (/^(subjective_[0-9]*)$/.test(answers[key])) {
        let index = answers[key].split('_')[1];
        ans = subjective_answer[index];
      }
      else {
        ans = answers[key];
      }
      this.ans_pool[que_id] = {
        answer: ans,
        time_taken: attempt_time[key],
        que_state: question_state[key]
      }
    });
    sessionStorage.setItem('questions-list', JSON.stringify(this.user_answers.questions));
    sessionStorage.setItem('attempted-time', JSON.stringify(this.user_answers.attempt_time));

  }

  evaluate() {
    console.log(this.ans_pool);
    
    console.log(this.questions)
    this.questions.forEach(que => {

      let correct_answer = this.getAnswerString(que);
      que.correct_answer = correct_answer;
      let user_answer = this.ans_pool[que.ques_id].answer;
      let que_time = parseInt(this.ans_pool[que.ques_id].time_taken);

      this.ans_stats.total_time_taken += que_time;
      if (user_answer == '-1') {
        this.ans_stats.not_attempted_ans++;
        this.ans_stats.not_attempted_marks -= parseFloat(que.not_attempt_marks);
      }
      else if (correct_answer == user_answer) {
        this.ans_stats.correct_ans++;
        this.ans_stats.correct_marks += parseFloat(que.positive_marks);
       // console.log(this.ans_stats);
      }
      else if(correct_answer != user_answer && user_answer != -1){
        this.ans_stats.incorrect_ans++;
        this.ans_stats.incorrect_ans_marks -= parseFloat(que.negative_marks);
       // console.log(this.ans_stats)
      }
    });

    let time_taken = Math.ceil((this.ans_stats.total_time_taken) / 1000)

    this.ans_stats.string_time_taken = Math.floor(time_taken / 3600)
      + ' : ' + ("0" + (Math.floor(time_taken / 60) % 60)).slice(-2)
      + ' : ' + ("0" + time_taken % 60).slice(-2);

    this.ans_stats.marks_obtained = parseFloat(this.ans_stats.correct_marks)-
      - parseFloat(this.ans_stats.incorrect_ans_marks)
      - parseFloat(this.ans_stats.not_attempted_marks);

    console.log('Answer status :', this.ans_stats);
    sessionStorage.setItem('ques_data',JSON.stringify(this.questions));

  }

  pad(num) {
    return ("0" + num).slice(-2);
  }

  getAnswerString(que) {
    var ans = [];
    if (que.ques_type_id == 2) { //Subjective
      return que.answer;
    }
    else {

      for (let i = 0; i < que.options.length; i++) {
        let option = que.options[i];
        switch (que.ques_type_id) {
          case 1: //Single Choice
          case 3: //Multi choice
            if (option.is_correct) {
              ans.push(String.fromCharCode(option.option_no + 96));
            }
            break;
          case 5: //True False
            if (option.is_correct) {
              ans.push('a');
            }
            else {
              ans.push('b');
            }
            break;
          case 6: //Match Matrix
          case 7: //Match Following
            if (option.is_correct)   // 4 = e, 5 = a
            {
              let choice_options = option.correct_ans.split('|');
              choice_options.forEach((el, key) => {
                choice_options[key] = String.fromCharCode(parseInt(el) + 96);
              });

              ans.push(option.option_no + '=' + choice_options.join('|'));
            }
            break;
          default:
            ans.push('-1');
        }
      }
      return ans.join(',');
    }
  }

 /*  calcPercent(score, marks) {
    return (((score / marks)) * 100).toFixed(2);
  }
 */
  calcPercent(){
    this.percentage = '';
    this.percentage = (((this.ans_stats.marks_obtained / this.test.total_marks)) * 100).toFixed(2);
   /*  return (((this.summary.total_score / this.summary.total_marks)) * 100).toFixed(2); */
  }



  selectChart(type) {
    if (type == 0) {
      this.loadSubjectWiseComponent = true;
      this.loadQuestionWiseComponent = false;
      this.loadTestSummary = false;
      this.tab_subject_wise_analysis = 'active';
      this.tab_question_wise_analysis = '';
      this.tab_summary = '';
      this.chartType = 0;
      this.guestSujectObj.groupQuestionsToSubject();

      // this.showQuestionWiseReport.emit();
    }
    else if (type == 1) {
      this.loadSubjectWiseComponent = false;
      this.loadQuestionWiseComponent = true;
      this.loadTestSummary = false;
      this.chartType = 1;
      this.tab_subject_wise_analysis = '';
      this.tab_question_wise_analysis = 'active';
      this.tab_summary = '';
      this.guestQuesObj.showQuestionWiseReport();
    }
    else {
      this.loadTestSummary = true;
      this.loadQuestionWiseComponent = false;
      this.loadSubjectWiseComponent = false;
      this.chartType = 2;
      this.tab_summary = 'active';
      this.tab_question_wise_analysis = '';
      this.tab_subject_wise_analysis = '';
      this.renderAnswerReportChart();

    }
  }


}

import { Component, OnInit, HostListener, NgZone, Host } from '@angular/core';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';

import * as globals from './../config/globals';
import { DomSanitizer } from '@angular/platform-browser';
import { PlatformLocation } from '@angular/common';
import 'zone.js/dist/zone';
import LZString from '../lz-string.js'
@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent implements OnInit {
  globals: any = globals.globals;

  loading: boolean;
  apiError: any;
  showError: boolean;

  testSettings: any;

  testSubmitted: boolean = false;
  showScoreCard: boolean = false;
  resultToken: string;
  is_test_completed: string;

  overlayShow: boolean = false;
  overlayMainIcon: string;
  overlayHelpMessage: string;
  overlayMainMessage: string;
  test_data: any;
  questionData: any;
  student_data: any;
  methodToBeInvoked: any;
  resizeEventCount: number = 0;
  showAlertPopup: boolean = false;
  alertPopUpCount: number = 0;



  constructor(public api: ApiService, private sanitizer: DomSanitizer, private router: Router, public location: PlatformLocation,
    ngZone: NgZone) {

    /*  window.onresize =(e) => {
       ngZone.run(() =>{
         console.log('width',e.target.document.documentElement.innerHeight)
       })
     } */
  }

  ngOnInit() {
    this.loading = true;
    this.overlayMainMessage = 'Please wait...';
    this.overlayHelpMessage = 'Initializing the platform';
    this.is_test_completed = sessionStorage.getItem('is_test_complete');
    this.student_data = JSON.parse(sessionStorage.getItem('student_data'));

    if (this.is_test_completed == '1') {
      this.testSubmitted = true;
      this.loading = false;
      this.overlayShow = true;
      this.overlayMainIcon = '<i class="fas fa-exclamation-triangle text-red"></i>';
      this.overlayMainMessage = 'Test is submitted or expired';
      this.overlayHelpMessage = 'This attempt has already been submitted.';
    }
    else {
      this.setup_panel();
    }
    // this.renderChart();
  }

  /* @HostListener('window:resize', ['$event'])
  onResize(event) {
    console.log(event); */
  /* var document = event.target;
  if(document['exitFullScreen'])
   this.resizeEventCount = this.resizeEventCount + 1;
   console.log(this.resizeEventCount) */
  /*   return;
  } */
   /*  @HostListener('document:keydown', ['$event'])
   handleKeyboardEvent(event) {
     console.log(event)
     if(event.keyCode == 65){
       event.preventDefault();
       
     }
     else {
       return;
     }
   } 
 */
  /* @HostListener('window:resize', ['$event'])
  onResize(event) {
    console.log(event.target.document)
    var doc = event.target.document;
    if(doc.webkitFullscreenEnabled){
      event.preventDefault();
    }
  } */

  /* @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent){
    console.log('Keyboard Event', event);
    //let action = confirm('You are trying to escape fullscreen. Test will be submitted');
    //event.preventDefault();
    //console.log('User wants to resume fullscreen', action);
    this.openfullscreen();
  } */

  /* @HostListener('document:MSFullscreenChange', ['$event'])
  event4(event){
    console.log(event)
  } */

 /*  @HostListener('document:fullscreenchange', ['$event'])
  event1(event){
    console.log('Event Fired', event);
    if(!event.currentTarget.fullscreen){
       var elem = event.target;
       let action = confirm('Do you want fullscreen?');
       if(action)
       {
        this.openfullscreen();
       }
    }    
  } */

 /*  @HostListener('document:webkitfullscreenchange', ['$event'])
  event2(event){
    console.log(event)
    if(event.target.webkitIsFullScreen == false){
      return;
    }
  }

  @HostListener('document:mozfullscreenchange', ['$event'])
  event3(event){
    console.log(event)
  }
  */

 
  openfullscreen() {
    // Trigger fullscreen
    const docElmWithBrowsersFullScreenFunctions = document.documentElement as HTMLElement & {
      mozRequestFullScreen(): Promise<void>;
      webkitRequestFullscreen(): Promise<void>;
      msRequestFullscreen(): Promise<void>;
    };

    if (docElmWithBrowsersFullScreenFunctions.requestFullscreen) {
      docElmWithBrowsersFullScreenFunctions.requestFullscreen();
    } else if (docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen) { /* Firefox */
      docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen();
    } else if (docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen();
    } else if (docElmWithBrowsersFullScreenFunctions.msRequestFullscreen) { /* IE/Edge */
      docElmWithBrowsersFullScreenFunctions.msRequestFullscreen();
    }
  }


  setup_panel() {

    this.api.begin_test().subscribe(res => {
     // console.log(res);
      /* var el = document.documentElement;
      this.methodToBeInvoked = el['requestFullscreen'] || el['msRequestFullscreen'] || el['mozRequestFullScreen'];
      if (this.methodToBeInvoked) {
        this.methodToBeInvoked.call(el);
      } */
      if (res['validate']) {
        let data = res['data'];
       // console.log('data:',data);
        if(data.languages !=undefined){
        sessionStorage.setItem('language-wise-questions', JSON.stringify(data.languages));
        }
        data.ques_data.map(
          (que, key) => {
            data.ques_data[key] = this.formatQuestion(que);
          }
        );

        var string = JSON.stringify(data.ques_data);
        //alert("Size of sample is: " + string.length);
        console.log('Size of sample is: ' + string.length);
        var compressed = '';
        if(string.length > 4000000) {
          let val = 1;
          sessionStorage.setItem('is_data_compress', JSON.stringify(val));
          compressed = LZString.compress(string);
          //alert("Size of compressed sample is: " + compressed.length);
          console.log('Size of compressed sample is: ' + compressed.length);
        } else {
          let val = 0;
          sessionStorage.setItem('is_data_compress', JSON.stringify(val));
          compressed = string
        }
        
        /* string = LZString.decompress(compressed);
        alert("Sample is: " + string); */

        sessionStorage.setItem('ques_data', compressed);
        sessionStorage.setItem('subjects', JSON.stringify(data.subjects_data));
        sessionStorage.setItem('test_settings', JSON.stringify(data.test_settings));
        this.test_data = JSON.parse(sessionStorage.getItem('test_data'));
        //console.log('test', this.test_data);
        this.testSettings = data.test_settings;
        if (this.student_data.userid !== 1234567890) {
          if (this.test_data.test_type_id !== 2) {
            if (this.testSettings.is_show_score == 1) {
              if ((data.ques_data.filter(el => el.is_auto_evaluate == 0 && el.ques_type_id == 2)).length < 1) {
                this.showScoreCard = true;
              }
              else {
                this.showScoreCard = false
              }
            }
            else {
              this.showScoreCard = false;
            }
          }
          else {
          //  console.log('manual eval ques', (data.ques_data.filter(el => { el.is_auto_evaluate == 0 && el.ques_type_id == 2 })).length);
            if ((data.ques_data.filter(el => el.is_auto_evaluate == 0 && el.ques_type_id == 2)).length < 1) {
              this.showScoreCard = true;
            }
            else {
              this.showScoreCard = false
            }
          }
        }
        else {
          this.showScoreCard = false;
        }
        /*  }
         else {
           if (this.testSettings.is_show_score == 1) {
             if ((data.ques_data.filter(el => el.is_auto_evaluate == 0 && el.ques_type_id == 2)).length < 1) {
               this.showScoreCard = true;
             }
             else {
               this.showScoreCard = false
             }
           }
           else {
             this.showScoreCard = false;
           } */
       // console.log('show score card or not:', this.showScoreCard);
        if (this.testSettings.is_allow_back_button == 1) {
          this.location.onPopState(() => {
          //  console.log('pressed back in add!!!!!');
            //this.router.navigateByUrl(â€˜/multicomponentâ€™);
            //history.forward();
          });
        }
        sessionStorage.setItem('attempt_data', JSON.stringify(data.attempt_data.attempt_data));
        if (data.attempt_data.attempt_data != null && data.attempt_data.attempt_data != undefined) {
          sessionStorage.setItem('test_status', data.attempt_data.attempt_data.answer_json);
        }
        else {
          sessionStorage.removeItem('test_status');
        }
      }
      this.loading = false;
    },
      error => {
        this.apiError = error;
        this.showError = true;
      });
  }

  /*
  ** Format Questions Text, Passage and Media Support
  **
  */

  formatQuestion(que) {
    que.question = decodeURIComponent(escape(atob(que.question)));
    que.passage = (que.passage != '') ? decodeURIComponent(escape(atob(que.passage))) : '';
    que.show_passage = (que.passage != '') ? true : false;

    if (que.options != undefined) {
      que.options.map((option, key) => {
        que.options[key].answer_option = decodeURIComponent(escape(atob(option.answer_option)));
        que.options[key].answer_choice_option = (option.answer_choice_option != '') ? decodeURIComponent(escape(atob(option.answer_choice_option))) : '';
      });
    }

    que.ques_media_data = this.generateMediaHTML(que.ques_media_data);
    que.passage_media_data = this.generateMediaHTML(que.passage_media_data);

    return que;
  }


  generateMediaHTML(data) {
    if (data == '' || data == null) {
      return '';
    }

    try {
      data = JSON.parse(data);
    }
    catch (e) {
      return '';
    }

    data.forEach(
      (media, key) => {
        try {
          data[key] = JSON.parse(media);
        }
        catch (e) {
          return;
        }
      }
    );

    return data;
  }

  processResult() {
    if (this.student_data.userid == 1234567890) {
      let saved = JSON.parse(sessionStorage.getItem('is_data_compress'));
      if(saved == 1) {
        this.questionData = JSON.parse(LZString.decompress(sessionStorage.getItem('ques_data')));
      } else {
        this.questionData = JSON.parse(sessionStorage.getItem('ques_data'));
      }
    //  console.log('ques data:', this.questionData);
      if ((this.questionData.filter(el => el.is_auto_evaluate == 0 && el.ques_type_id == 2)).length < 1) {
        this.router.navigate(['/score'], { replaceUrl: true });
      }
      else {
        this.testSubmitted = true;
      }
    }
    if (this.showScoreCard && (parseInt(this.testSettings.is_show_score) + parseInt(this.testSettings.is_show_marks) > 0)) {
      this.router.navigate(['/result'], { replaceUrl: true })
    }
    else {
      this.testSubmitted = true;
    }
  }

  closeWindow() {
    window.close();
  }


}
